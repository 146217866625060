import React from 'react';
import PropTypes from 'prop-types';

import ExternalToolbar from './ExternalToolbar';

const ToolBar = ({
  bodyFont,
  language,
  editorState,
  onToggle,
  onToggleBlockType,
  onToggleFontInlineStyle,
  onToggleCustomBockStyle,
  onClearFormatting,
  onSplitSection,
  dispatch,
  zoomValue,
  selectionActive,
  ...rest
}) => {
  return (
    <div
      className="rich-editor-header"
      style={{
        zoom: 100 / zoomValue,
      }}>
      <div className="rich-editor-toolbar">
        <ExternalToolbar
          selectionActive={selectionActive}
          language={language?.toLowerCase()}
          bodyFont={bodyFont}
          editorState={editorState}
          onToggle={onToggle}
          onToggleBlockType={onToggleBlockType}
          onToggleFontInlineStyle={onToggleFontInlineStyle}
          onToggleCustomBockStyle={onToggleCustomBockStyle}
          onClearFormatting={onClearFormatting}
          onSplitSection={onSplitSection}
          dispatch={dispatch}
          {...rest}
        />
      </div>
    </div>
  );
};

ToolBar.defaultProps = {
  bodyFont: '',
  zoomValue: 100,
  selectionActive: false,
};

ToolBar.propTypes = {
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  language: PropTypes.string.isRequired,
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggle: PropTypes.func.isRequired,
  onToggleBlockType: PropTypes.func.isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  onToggleCustomBockStyle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClearFormatting: PropTypes.func.isRequired,
  onSplitSection: PropTypes.func.isRequired,
  zoomValue: PropTypes.number,
  selectionActive: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default ToolBar;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useIntercom } from 'react-use-intercom';

import helpers from 'helpers/proposal';
import ConfirmModal from 'components/ConfirmModal';
import AttachmentModal from './AttachmentModal/AttachmentModal';
import { ImportSectionModal, ReOrderSectionModal, IsEditingModal } from './';
import { DownloadingPDFModal, DownloadPdfErrorModal, KeyboardShortcutModal } from '../../Modals';

const DraftModals = ({
  prop,
  saveProposal,
  selectedPosition,
  scrollToSection,
  selectedSectionName,
  setSelectedSectionName,
  importSectionModal,
  showImportSectionModal,
  confirmModal,
  showConfirmModal,
  reOrderSectionModal,
  showReOrderSectionModal,
  downloadingPDF,
  downloadPDFError,
  setDownloadPDFError,
  attachmentModal,
  showAttachmentModal,
  isEditingModal,
  takeOverProposal,
  saveLastSectionOrder,
  propRef,
  setProp,
  setSectionOrderArrangement,
  wixEditor,
  keyboardModal,
  setKeyboardModal,
  templateWixEditor,
  isTemplate,
}) => {
  const { trackEvent } = useIntercom();

  const importSectionToProposal = ({ importedSection }) => {
    const sectionId = uuidv4();

    if (prop) {
      const newsection = JSON.parse(JSON.stringify(importedSection));
      const sectionOrder = helpers.findSectionOrder({
        proposal: prop,
        sectionId,
        sectionName: selectedSectionName,
        position: selectedPosition,
      });

      if (!prop?.wixProposalId) {
        trackEvent('section-imported');
      }

      saveProposal(
        {
          'draft.sectionorder': sectionOrder,
          [`draft.${sectionId}`]: newsection,
        },
        ({ data }) => {
          if (data) {
            showImportSectionModal(false);
            scrollToSection({ sectionId });
            saveLastSectionOrder(sectionOrder);
            saveLastSectionOrder(false);
            setSectionOrderArrangement(sectionOrder);
          }
        }
      );
    } else {
      showImportSectionModal(false);
    }
  };

  const deleteSection = () => {
    let sectionOrder = prop.draft.sectionorder || helpers.defaultSectionOrder;
    sectionOrder = _.without(sectionOrder, selectedSectionName);

    showConfirmModal(false);
    saveProposal(
      {
        'draft.sectionorder': sectionOrder,
      },
      ({ data }) => {
        if (data) {
          showConfirmModal(false);
          setSelectedSectionName(null);
          saveLastSectionOrder(sectionOrder);
          saveLastSectionOrder(false);
          setSectionOrderArrangement(sectionOrder);
        }
      }
    );
  };

  const reOrderSection = ({ sectionOrder }) => {
    const newSectionOrder = sectionOrder.map((s) => {
      return s.id;
    });

    _.each(prop.draft.sectionorder, (id, idx) => {
      if (newSectionOrder.indexOf(id) === -1) {
        newSectionOrder.splice(idx, 0, id);
      }
    });

    saveProposal(
      {
        'draft.sectionorder': newSectionOrder,
      },
      ({ data }) => {
        if (data) {
          showReOrderSectionModal(false);
          saveLastSectionOrder(newSectionOrder);
          saveLastSectionOrder(false);
          setSectionOrderArrangement(newSectionOrder);
        }
      }
    );
  };

  return (
    <>
      {importSectionModal && (
        <ImportSectionModal
          visible={importSectionModal}
          onCancel={() => showImportSectionModal(false)}
          onConfirm={importSectionToProposal}
          proposal={prop}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Are You Sure?"
          body="This will delete the whole section and cannot be undone"
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={deleteSection}
        />
      )}
      {reOrderSectionModal && (
        <ReOrderSectionModal
          onConfirm={reOrderSection}
          proposal={prop}
          onCancel={() => showReOrderSectionModal(false)}
        />
      )}
      {downloadingPDF && <DownloadingPDFModal />}
      {downloadPDFError && (
        <DownloadPdfErrorModal
          downloadPDFError={downloadPDFError}
          onCancel={() => setDownloadPDFError('')}
        />
      )}

      {attachmentModal && (
        <AttachmentModal
          propRef={propRef}
          setProp={setProp}
          prop={prop}
          visible={attachmentModal}
          showAttachmentModal={showAttachmentModal}
          isTemplate={isTemplate || templateWixEditor}
        />
      )}

      {isEditingModal && (
        <IsEditingModal
          isEditingModal={isEditingModal}
          isEditing={prop?.isEditing}
          proposalId={prop._id}
          takeOverProposal={takeOverProposal}
          wixEditor={wixEditor}
          templateWixEditor={templateWixEditor}
        />
      )}

      {keyboardModal && (
        <KeyboardShortcutModal
          visible={keyboardModal}
          handleCancel={() => setKeyboardModal(false)}
        />
      )}
    </>
  );
};

DraftModals.defaultProps = {
  prop: '',
  selectedPosition: '',
  selectedSectionName: '',
  downloadPDFError: '',
  importSectionModal: false,
  confirmModal: false,
  reOrderSectionModal: false,
  downloadingPDF: false,
  wixEditor: false,
  templateWixEditor: false,
  setSelectedSectionName: () => {},
  saveProposal: () => {},
  scrollToSection: () => {},
  showImportSectionModal: () => {},
  showConfirmModal: () => {},
  showReOrderSectionModal: () => {},
  setDownloadPDFError: () => {},
  saveLastSectionOrder: () => {},
  keyboardModal: false,
  setKeyboardModal: () => {},
};

DraftModals.propTypes = {
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  selectedPosition: PropTypes.string,
  selectedSectionName: PropTypes.string,
  downloadPDFError: PropTypes.string,
  importSectionModal: PropTypes.bool,
  confirmModal: PropTypes.bool,
  reOrderSectionModal: PropTypes.bool,
  downloadingPDF: PropTypes.bool,
  isEditing: PropTypes.bool,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
  setSelectedSectionName: PropTypes.func,
  saveProposal: PropTypes.func,
  scrollToSection: PropTypes.func,
  showImportSectionModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  showReOrderSectionModal: PropTypes.func,
  setDownloadPDFError: PropTypes.func,
  attachmentModal: PropTypes.bool,
  showAttachmentModal: PropTypes.func,
  takeOverProposal: PropTypes.func,
  saveLastSectionOrder: PropTypes.func,
  keyboardModal: PropTypes.bool,
  setKeyboardModal: PropTypes.func,
  isTemplate: PropTypes.bool,
};

export default DraftModals;

import React from 'react';
import { Row, Col } from 'antd';
import './ProposalItemLoader.scss';
import { proposalItem } from 'helpers/grid';

const Skelton = (props) => <div className="skelton" {...props}></div>;

const ProposalItemLoader = () => {
  return (
    <Row className="item" justify="middle" align="top">
      <Col {...proposalItem.proposal} className="value value-proposal">
        <Skelton />
      </Col>
      <Col {...proposalItem.client} className="value value-client">
        <Skelton style={{ maxWidth: '80%' }} />
      </Col>
      <Col {...proposalItem.watched} className="value value-watched">
        <Skelton />
      </Col>
      <Col {...proposalItem.opened} className="value value-opened">
        <Skelton />
      </Col>
      <Col {...proposalItem.quote} className="value value-quote">
        <Skelton />
      </Col>
      <Col {...proposalItem.status} className="value action-column value-status">
        <Skelton />
      </Col>
      <Col {...proposalItem.action} />
    </Row>
  );
};

export default ProposalItemLoader;
